

























import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private cType: any;
  /**
   * 切换左边导航
   */
  private change(type: any) {
    const time: any = new Date().getTime();
    this.$router.replace({
      path: "/main/datacenter/pool/duijie",
      query: {
        type,
        time,
      },
    });
  }
}
