













import { Component, Vue, Prop } from "vue-property-decorator";
import she from "./she.vue";
import mian from "./mian.vue";
import mai from "./mai.vue";
@Component({
  components: {
    she,
    mian,
    mai,
  },
})
export default class Name extends Vue {
  @Prop()
  private data: any;
}
