import { render, staticRenderFns } from "./manbingguanli-detail.vue?vue&type=template&id=2967479c&scoped=true&"
import script from "./manbingguanli-detail.vue?vue&type=script&lang=ts&"
export * from "./manbingguanli-detail.vue?vue&type=script&lang=ts&"
import style0 from "./manbingguanli-detail.vue?vue&type=style&index=0&id=2967479c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2967479c",
  null
  
)

export default component.exports