





















































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private goDetail(item: any) {
    this.$store.commit("updateIfCompose", false);
    this.$router.push({
      path: "/main/storehouse/case/detail",
      query: {
        id: item.zhenci_id,
        source: "shujuchi",
      },
    });
  }
}
