

































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import {
  PostMenzhenDetail,
  GetListData,
  GetDetailData,
} from "@/request/differences";
import { GetZhenciList, AddZhenci } from "@/request/storehouse";
import Pagination from "@/components/paginaiton.vue";
import tizhibianshiList from "@/components/duijie/tizhibianshi/tizhibianshi-list.vue";
import tizhibianshiDetail from "@/components/duijie/tizhibianshi/tizhibianshi-detail.vue";
import bingliList from "@/components/duijie/bingli/bingli-list.vue";
import manbingguanliList from "@/components/duijie/manbingguanli/manbingguanli-list.vue";
import manbingguanliDetail from "@/components/duijie/manbingguanli/manbingguanli-detail.vue";
import xinlicepingList from "@/components/duijie/xinliceping/xinliceping-list.vue";
import xinlicepingDetail from "@/components/duijie/xinliceping/xinliceping-detail.vue";
import yingxiangList from "@/components/duijie/yingxiang/yingxiang-list.vue";
import yingxiangDetail from "@/components/duijie/yingxiang/yingxiang-detail.vue";
import zhenliaoList from "@/components/duijie/zhenliao/zhenliao-list.vue";
import zhenliaoDetail from "@/components/duijie/zhenliao/zhenliao-detail.vue";
import jiankangList from "@/components/duijie/jiankang/jiankang-list.vue";
import jiankangDetail from "@/components/duijie/jiankang/jiankang-detail.vue";
import Detail from "@/components/duijie/sizhen/sizhen-detail.vue";
import leftNav from "@/components/duijie/left-nav/left-nav.vue";
@Component({
  components: {
    Pagination,
    tizhibianshiList,
    tizhibianshiDetail,
    bingliList,
    manbingguanliList,
    manbingguanliDetail,
    xinlicepingList,
    xinlicepingDetail,
    yingxiangList,
    yingxiangDetail,
    zhenliaoList,
    zhenliaoDetail,
    jiankangList,
    jiankangDetail,
    leftNav,
    Detail,
  },
})
export default class Name extends mixins(listMixin) {
  private cType: any = "";
  private showList: boolean = true;
  private listData: any = [];
  private detailData: any = [];
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private detailH: any = 0;
  @Watch("$route")
  private routechange(to: any, from: any) {
    if (this.$route.query.type) {
      this.cType = this.$route.query.type;
    }
    this.filters.search = "";
    this.filters.total_count = 0;
    this.filters.current_page = 1;
    this.filters.page_count = 10;
    this.filters.startTime = null;
    this.filters.endTime = null;
    this.showList = true;
    this.getList();
  }
  private get timeText() {
    let text = "";
    if (this.cType === "门诊") {
      text = "就诊日期";
    } else if (this.cType === "住院") {
      text = "入院时间";
    } else if (this.cType === "门特") {
      text = "就诊日期";
    } else if (this.cType === "检查") {
      text = "申请时间";
    } else if (this.cType === "检验") {
      text = "完成时间";
    } else if (this.cType === "中医影像") {
      text = "采集时间";
    } else {
      text = "时间";
    }
    return text;
  }
  private get user() {
    return this.$store.state.user;
  }
  private get patient() {
    return this.$store.state.patient;
  }
  private get center() {
    return this.$store.state.center;
  }
  private startChange(e: any) {
    this.filters.startTime = e;
  }
  /**
   * @description 添加诊次
   */
  private addOne() {
    const params: any = {
      project_id: this.center.project_id,
      group_id: this.center.group_id,
      patient_id: this.patient.patient_id,
      type: "1",
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    AddZhenci(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.$store.commit("updateIfCompose", false);
        this.$router.push({
          path: "/main/storehouse/case/detail",
          query: {
            id: data.zhenci_id,
            source: "shujuchi",
          },
        });
      })
      .catch(() => {
        loading.close();
      });
  }
  private get placeholderText() {
    let text = "";
    if (this.cType === "体质辨识") {
      text = "请搜索报告名称";
    } else {
      text = "请搜索";
    }
    return text;
  }
  private beginDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.endTime) {
          return (
            time.getTime() > new Date(self.filters.endTime).getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  private endDate() {
    const self = this;
    return {
      disabledDate(time: any) {
        if (self.filters.startTime) {
          return (
            new Date(self.filters.startTime).getTime() > time.getTime() ||
            time.getTime() > Date.now()
          );
        } else {
          return time.getTime() > Date.now();
        }
      },
    };
  }
  getList() {
    if (this.cType === "病例数据") {
      const params = {
        params: {
          doctor_id: this.patient.doctor_id,
          hospital_id: this.patient.hospital_id,
          patient_id: this.patient.patient_id,
          project_id: this.center.project_id,
          page_num: this.filters.page_count,
          current_page: this.filters.current_page,
          start_time: this.filters.startTime,
          end_time: this.filters.endTime,
          search: this.filters.search,
        },
      };
      GetZhenciList(this, params).then((res) => {
        this.listData = res.data;
        this.filters.total_page = res.total_page;
        this.filters.total_count = res.total_count;
      });
    } else {
      const params = {
        doctor_id: this.patient.doctor_id,
        hospital_id: this.patient.hospital_id,
        patient_id: this.patient.patient_id,
        page_num: this.filters.page_count,
        current_page: this.filters.current_page,
        start_time: this.filters.startTime,
        end_time: this.filters.endTime,
        search: this.filters.search,
        type: this.cType,
      };
      GetListData(this, params).then((res) => {
        this.listData = res.data;
        this.filters.total_page = res.total_page;
        this.filters.total_count = res.total_count;
      });
    }
  }
  private goback() {
    this.$router.go(-1);
  }
  private goDetail(item: any) {
    if (this.cType === "门诊") {
      const params = {
        patId: item.patId,
      };
      PostMenzhenDetail(this, params).then((res: any) => {
        this.detailData = res;
      });
    } else {
      const params = {
        type: item["报告名称"],
        id: item.id,
      };
      GetDetailData(this, params).then((res: any) => {
        this.detailData = res;
        this.showList = false;
      });
    }
  }
  mounted() {
    if (this.$route.query.type) {
      this.cType = this.$route.query.type;
    }
    this.getList();
  }
}
