






































import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private goDetail(item: any) {
    this.$emit("jumpDetail", item);
  }
  private mounted() {}
}
